<template>
  <app-page-layout
    v-bind="content"
    class="page-accessories"
  >
    <div class="page-accessories__accessories">
      <app-accessory-block
        v-for="(accessory, index) in content.data"
        :key="index"
        :accessory="accessory"
      />
    </div>
  </app-page-layout>
</template>

<script>
import AppPageLayout from '@/components/app-page-layout';
import AppAccessoryBlock from '@/components/app-accessory-block';

export default {
  components: {
    AppPageLayout,
    AppAccessoryBlock,
  },
  computed: {
    content() {
      return this.$t('page.accessories');
    },
  },
};
</script>

<style lang="scss">
.page-accessories {
  &__accessories {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    .app-accessory-block {
      flex: 0 0 auto;
    }
  }
}
</style>
