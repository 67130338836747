<template>
  <div class="app-accessory-block">
    <div class="app-accessory-block__container">
      <div v-if="isNew" class="app-accessory-block__label">{{ $t('label.device.new') }}</div>
      <div class="app-accessory-block__image">
        <img :src="$t(accessory.image)" alt="">
      </div>

      <div class="app-accessory-block__data">
        <div class="app-accessory-block__name">{{ accessory.name }}</div>
        <div class="app-accessory-block__feature">{{ accessory.feature }}</div>
        <div class="app-accessory-block__lead">{{ accessory.lead }}</div>
        <div class="app-accessory-block__description">{{ accessory.description }}</div>
        <div class="app-accessory-block__price">{{ accessory.price }}</div>
        <div class="app-accessory-block__buttons">
          <a
            v-if="false && accessory.button?.first"
            class="btn"
            :href="accessory.button.first.url"
          >{{ accessory.button.first.text }}</a>
          <a
            v-if="false && accessory.button?.second"
            class="btn btn--secondary"
            :href="accessory.button.second.url"
          >{{ accessory.button.second.text }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['accessory'],
  computed: {
    isNew() {
      return this.accessory.new && this.accessory.new !== 'false' && this.accessory.new !== '0';
    },
  },
};
</script>

<style lang="scss">
.app-accessory-block {
  position: relative;

  margin: 45px 14px 48px;

  width: 675px;
  min-height: 363px;

  background: #F8F7F7;
  border-radius: 12px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  display: flex;
  align-items: stretch;
  justify-content: stretch;

  &__container {
    // @include container;
    display: flex;
    align-items: stretch;

    position: relative;
  }

  &__label {
    position: absolute;
    padding: 10px 40px;
    font-weight: 700;
    font-size: 34px;
    line-height: 40px;
    color: #FFFFFF;
    left: 70px;
    transform: translateY(-50%);
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        184.27deg,
        rgba(255, 1, 1, 0.85) 8.61%,
        rgba(255, 72, 3, 0.75) 98.64%,
        rgba(255, 94, 3, 0.65) 126.2%
      );
      transform: skewX(-15deg);
      z-index: -1;
    }
  }

  &__data {
    flex: 1 1 50%;
    max-width: 50%;
    padding-top: 32px;
    padding-bottom: 24px;
    padding-right: 12px;

    display: flex;
    flex-direction: column;
  }

  &__image {
    flex: 1 1 50%;
    max-width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 32px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__name {
    font-weight: 700;
    font-size: 40px;
    line-height: 138.19%;
    color: #CA1025;
  }

  &__feature {
    font-weight: 700;
    font-size: 24px;
    line-height: 138.19%;
    color: #343434;
    padding-bottom: 4px;
  }

  &__lead {
    font-weight: 500;
    font-size: 18px;
    line-height: 110%;

    background: linear-gradient(89.96deg, #FF0000 19%, rgba(247, 95, 9, 0.65) 97.23%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    padding-bottom: 24px;
  }

  &__description {
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    color: #292929;
    padding-bottom: 24px;
  }

  &__price {
    margin-top: auto;
    font-weight: 500;
    font-size: 18px;
    line-height: 110%;
    color: #292929;
    padding-bottom: 16px;
  }

  &__buttons {
    .btn {
      margin-right: 16px;
      &:last-child {
        margin-right: 0;
      }

      margin-bottom: 8px;
    }
  }

  @include media-bp(tab) {
    margin: 28px 10px 28px;

    width: 380px;
    min-height: 0;

    &__label {
      font-size: 30px;
      line-height: 32px;
      padding: 6px 24px;
      left: 24px;
    }

    &__data {
      flex: 1 1 66%;
      max-width: 66%;
      padding-top: 24px;
      padding-bottom: 16px;
      padding-right: 8px;
    }

    &__image {
      flex: 1 1 33%;
      max-width: 33%;
      padding: 8px;
    }

    &__name {
      font-size: 28px;
    }

    &__feature {
      font-size: 18px;
      padding-bottom: 4px;
    }

    &__lead {
      font-size: 16px;
      padding-bottom: 18px;
    }

    &__description {
      font-size: 16px;
      padding-bottom: 18px;
    }

    &__price {
      font-size: 16px;
      padding-bottom: 12px;
    }
  }

  @include media-bp(mob) {
    margin-top: 50px;
    width: 100%;

    &__container {
      @include container;
      flex-direction: column;
    }

    &__label {
      left: 16px;
      font-size: 25px;
      line-height: 28px;
      padding: 2px 12px;
    }

    &__image,
    &__data {
      flex: 0 0 auto;
      max-width: 100%;
      padding: 0;
      margin-bottom: 24px;
    }

    &__image {
      margin: -80px auto 8px;

      width: 220px;
      height: 180px;

      align-items: flex-end
    }

    &__name {
      font-size: 40px;
      line-height: 110%;

      margin-bottom: 4px;
    }

    &__feature {
      font-size: 24px;
      line-height: 110%;

      margin-bottom: 4px;
    }

    &__lead {
      font-size: 18px;
      line-height: 110%;

      padding-bottom: 0;
      margin-bottom: 12px;
    }

    &__description {
      font-size: 18px;
      line-height: 110%;
    }
  }
}
</style>
