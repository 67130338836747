<template>
  <div class="app-page-layout">
    <div class="app-page-layout__header">
      <div class="app-page-layout__header-container">
        <div class="app-page-layout__header-image">
          <img v-if="image" :src="image" alt="">
        </div>

        <div class="app-page-layout__header-top">
          <div class="app-page-layout__header-subtitle">{{ subtitle }}</div>
          <div class="app-page-layout__header-title">{{ title }}</div>
        </div>

        <div class="app-page-layout__header-body">
          <div class="app-page-layout__header-lead">{{ lead }}</div>
          <slot name="header" />
        </div>

        <div class="app-page-layout__header-control">
          <slot name="control" />
        </div>
      </div>
    </div>

    <div class="app-page-layout__body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    lead: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.app-page-layout {

  &__header {
    overflow: hidden;

    $header-top-height: 236px;

    &-container {
      @include container;

      position: relative;
      display: flex;
      flex-direction: column;
    }

    &-image {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;

      max-height: 100%;
      width: 40%;

      padding-right: 60px;

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 100%;
      }

      z-index: 0;
    }

    &-top {
      position: relative;

      padding-left: 40%;
      height: $header-top-height;

      padding-top: 12px;
      padding-bottom: 8px;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;

      &::before {
        content: "";
        position: absolute;
        width: 200vw;
        left: -100vw;
        right: -100%;
        top: 0;
        bottom: 0;
        background: $red;
        z-index: -1;
      }
    }

    &-body {
      padding-left: 40%;
      padding-top: 8px;
    }

    &-title {
      max-width: 600px;
      font-weight: 700;
      font-size: 75px;
      line-height: 95.5%;
      color: #FFFFFF;
    }

    &-subtitle {
      max-width: 600px;
      font-weight: 400;
      font-size: 36px;
      line-height: 95.5%;
      color: #FFFFFF;
    }

    &-lead {
      max-width: 600px;
      font-weight: 400;
      font-size: 20px;
      line-height: 138.19%;
      color: #222222;
      white-space: pre-line;
    }

    &-control {
      padding-left: 40%;
      padding-top: 24px;
    }
  }

  &__body {
    padding-top: 30px;
  }

  @include media-bp(tab) {
    $header-top-height: 124px;

    &__header {
      &-top {
        height: $header-top-height;
      }

      &-title {
        font-size: 46px;
        max-width: 100%;
      }

      &-subtitle {
        max-width: 100%;
        font-size: 26px;
      }

      &-lead {
        max-width: 100%;
        font-size: 16px;
      }

      &-control {
        padding-top: 20px;
      }
    }
  }

  @include media-bp(mob) {
    $header-top-height: 124px;
    $image-height: $header-top-height + 94px;

    &__header {
      &-title {
        font-size: 26px;
      }

      &-subtitle {
        font-size: 18px;
      }

      &-lead {
        font-size: 16px;
      }

      &-image {
        padding: 0;
        height: $image-height;
        width: 50%;
      }

      &-top {
        height: $header-top-height;
        padding-left: 50%;
      }

      &-body {
        order: 2;
        padding-left: 0;
        background-image: url('./img/back.png');
        background-repeat: no-repeat;
        background-position: top right;
      }

      &-control {
        order: 1;
        padding-left: 50%;
        padding-top: 16px;
        min-height: $image-height - $header-top-height;
      }
    }
  }
}
</style>
